//SECTION - IMPORT
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { lazy, Suspense, useEffect, useState } from "react";
import { Role, TeachCtl } from "controllers/teach.ctl";
import { TeachContext } from "components/teach.context";
import CoreProvider, { useCore } from "components/core-sub/context";
import MainContainer from "components/core-sub/MainContainer";
import { NotFound } from "components/core-sub/NotFound";
import { ProfileAddonsMenu } from "components/profile.addons.menu";
import { Loading } from "components/core-sub/Loading";
import { app } from "components/core-sub/Controller/firebase";
import { CourseSectionContainer } from "pages/course.section.container";
import { TeacherContainer } from "pages/teacher.container";
//!SECTION

//SECTION - LAZY LOADING
const PageRestrict = lazy(() => import("pages/restrict"));
const PageCourseManager = lazy(() => import("pages/course.manager"));
const PageCourseMaterialManager = lazy(
  () => import("pages/course.material.manager")
);
const PageCourseSyllabus = lazy(() => import("pages/course.syllabus"));
const PageCourseQuizManager = lazy(() => import("pages/course.quiz.manager"));
const PageCourseQuizView = lazy(() => import("pages/course.quiz.view"));
const PageCourseSectionManager = lazy(
  () => import("pages/course.section.manager")
);
const PageCourseSyllabusEditor = lazy(
  () => import("pages/course.syllabus.editor")
);
const PageCourseQuizQuestionManager = lazy(
  () => import("pages/course.quiz.question.manager")
);
const PageCourseQuizQuestionEditor = lazy(
  () => import("pages/course.quiz.question.editor")
);
const PageCourseSectionAssignmentList = lazy(
  () => import("pages/course.section.assignment.list")
);
const PageCourseLessonEditor = lazy(() => import("pages/course.lesson.editor"));
const PageCourseSectionInbox = lazy(() => import("pages/course.section.inbox"));
const PageCourseSectionInboxList = lazy(
  () => import("pages/course.section.inbox.list")
);
const PageCourseSectionStudentManager = lazy(
  () => import("pages/course.section.student.manager")
);
const PageCourseSectionAssignmentReview = lazy(
  () => import("pages/course.section.assignment.review")
);
const PageStudentManager = lazy(() => import("pages/course.student.manager"));
const PageTeacherManager = lazy(() => import("pages/course.teacher.manager"));
const PageCourseSectionTeacherManager = lazy(
  () => import("pages/course.section.teacher.manager")
);
const PageTeacherStudentList = lazy(() => import("pages/teacher.student.list"));
const PageTeacherAssignmentList = lazy(
  () => import("pages/teacher.assignment.list")
);
const PageTeacherAssignmentReview = lazy(
  () => import("pages/teacher.assignment.review")
);
const PageTeacherInbox = lazy(() => import("pages/teacher.inbox"));
const PageTeacherInboxList = lazy(() => import("pages/teacher.inbox.list"));
const PageCourseSectionGrading = lazy(
  () => import("pages/course.section.grading")
);
const PageLessonPreview = lazy(() => import("pages/lesson.preview"));
const PagePreviewCourse = lazy(() => import("pages/preview.course"));
const PagePreviewQuiz = lazy(() => import("pages/preview.quiz"));
const PageTeacherGrading = lazy(() => import("pages/teacher.grading"));
const PageQuizPreview = lazy(() => import("pages/quiz.preview"));
const PageTest = lazy(() => import("pages/test"));
const PageCourseClass = lazy(() => import("pages/course.section.class"));
const PageTeacherClass = lazy(() => import("pages/teacher.class"));
//!SECTION

//SECTION - Routing
const Routing = () => {
  //ANCHOR - STATE
  const { user } = useCore();
  const [role, setRole] = useState<{ loading: boolean; value: Role }>({
    loading: true,
    value: "anonymous",
  });

  //ANCHOR - USEEFFECT
  useEffect(() => {
    if (user.loading === false && user.data) {
      TeachCtl.getRole(user.data).then((data) =>
        setRole({ loading: false, value: data.role })
      );
    } else {
      setRole({ loading: true, value: "anonymous" });
    }
  }, [user]);

  if (user.loading === true && role.loading === true) {
    return <MainContainer loading> </MainContainer>;
  }

  if (!user.data) {
    return <MainContainer signInOnly />;
  }

  if (
    role.loading === false &&
    !(["owner", "teacher"] as Role[]).includes(role.value)
  ) {
    return <PageRestrict />;
  }

  //SECTION - RENDER
  return (
    <TeachContext.Provider value={{ role }}>
      <Suspense fallback={<Loading />}>
        <Routes>
          {process.env.NODE_ENV === "development" && (
            <Route path="/test" element={<PageTest />} />
          )}
          <Route
            path="/course/preview/:courseId/*"
            element={
              <Routes>
                <Route path="/quiz/:quizId" element={<PagePreviewQuiz />} />
                <Route
                  path="/assignment/:assignmentId"
                  element={<PagePreviewCourse />}
                />
                <Route
                  path="/lesson/:lessonId"
                  element={<PagePreviewCourse />}
                />
                <Route path="/" element={<PagePreviewCourse />} />
              </Routes>
            }
          />
          <Route path="/syllabus/preview/" element={<PageLessonPreview />} />
          <Route path="/lesson/preview/" element={<PageLessonPreview />} />
          <Route path="/quiz/preview/:quizId" element={<PageQuizPreview />} />
          <Route path="/quiz/preview/" element={<PageQuizPreview />} />
          <Route
            path="/teacher/section/:sectionId/assignment/:assignmentId"
            element={<PageTeacherAssignmentReview />}
          />
          <Route
            path="/teacher/section/:sectionId/*"
            element={
              <TeacherContainer>
                <Routes>
                  <Route path="/class" element={<PageTeacherClass />} />
                  <Route path="/grading" element={<PageTeacherGrading />} />
                  <Route
                    path="/inbox/:studentId"
                    element={<PageTeacherInboxList />}
                  />
                  <Route path="/inbox" element={<PageTeacherInbox />} />
                  <Route
                    path="/assignment"
                    element={<PageTeacherAssignmentList />}
                  />
                  <Route path="/*" element={<PageTeacherStudentList />} />
                </Routes>
              </TeacherContainer>
            }
          />
          <Route path="/manage/teacher" element={<PageTeacherManager />} />
          <Route path="/manage/student" element={<PageStudentManager />} />
          {/*
          //SECTION - Course
          */}
          <Route
            path="/edit/:courseId/*"
            element={
              <Routes>
                {/*
                //SECTION - section 
                 */}
                <Route
                  path="/section/*"
                  element={
                    <Routes>
                      <Route
                        path="/:sectionId/assignment/:assignmentId"
                        element={<PageCourseSectionAssignmentReview />}
                      />
                      <Route
                        path="/:sectionId/inbox/:studentId"
                        element={<PageCourseSectionInboxList />}
                      />
                      <Route
                        path="/:sectionId/*"
                        element={
                          <CourseSectionContainer>
                            <Routes>
                              <Route
                                path="/class"
                                element={<PageCourseClass />}
                              />
                              <Route
                                path="/teacher"
                                element={<PageCourseSectionTeacherManager />}
                              />
                              <Route
                                path="/grading"
                                element={<PageCourseSectionGrading />}
                              />
                              <Route
                                path="/assignment"
                                element={<PageCourseSectionAssignmentList />}
                              />
                              <Route
                                path="/student"
                                element={<PageCourseSectionStudentManager />}
                              />
                              <Route
                                path="/inbox"
                                element={<PageCourseSectionInbox />}
                              />
                              <Route
                                path="/"
                                element={<PageCourseSectionStudentManager />}
                              />
                              <Route
                                path="/*"
                                element={<PageCourseSectionStudentManager />}
                              />
                            </Routes>
                          </CourseSectionContainer>
                        }
                      />
                      <Route
                        path="/edit/:courseId/section/:sectionId/assignment/:assignmentId"
                        element={<PageCourseSectionAssignmentReview />}
                      />
                      <Route
                        path="/edit/:courseId/section/:sectionId/inbox/:studentId"
                        element={<PageCourseSectionInboxList />}
                      />
                      <Route
                        path="/edit/:courseId/section/:sectionId/*"
                        element={
                          <CourseSectionContainer>
                            <Routes>
                              <Route
                                path="/class"
                                element={<PageCourseClass />}
                              />
                              <Route
                                path="/teacher"
                                element={<PageCourseSectionTeacherManager />}
                              />
                              <Route
                                path="/grading"
                                element={<PageCourseSectionGrading />}
                              />
                              <Route
                                path="/assignment"
                                element={<PageCourseSectionAssignmentList />}
                              />
                              <Route
                                path="/student"
                                element={<PageCourseSectionStudentManager />}
                              />
                              <Route
                                path="/inbox"
                                element={<PageCourseSectionInbox />}
                              />
                              <Route
                                path="/"
                                element={<PageCourseSectionStudentManager />}
                              />
                              <Route
                                path="/*"
                                element={<PageCourseSectionStudentManager />}
                              />
                            </Routes>
                          </CourseSectionContainer>
                        }
                      />
                      <Route path="/" element={<PageCourseSectionManager />} />
                    </Routes>
                  }
                />
                {/* 
                //!SECTION
                */}
                <Route
                  path="/quiz/*"
                  element={
                    <Routes>
                      <Route
                        path="/:quizId/question/:questionId"
                        element={<PageCourseQuizQuestionEditor />}
                      />
                      <Route
                        path="/:quizId/preview"
                        element={<PageCourseQuizView />}
                      />
                      <Route
                        path="/:quizId"
                        element={<PageCourseQuizQuestionManager />}
                      />
                      <Route path="/" element={<PageCourseQuizManager />} />
                    </Routes>
                  }
                />
                <Route
                  path="/syllabus/editor"
                  element={<PageCourseSyllabusEditor />}
                />
                <Route path="/syllabus" element={<PageCourseSyllabus />} />
                <Route
                  path="/lesson/:lessonId"
                  element={<PageCourseLessonEditor />}
                />
                <Route
                  path="/material"
                  element={<PageCourseMaterialManager />}
                />
                <Route path="/" element={<PageCourseMaterialManager />} />
              </Routes>
            }
          />
          {/*
          //!SECTION
          */}
          <Route path="/" element={<PageCourseManager />} />
          <Route
            path="/*"
            element={
              <MainContainer>
                <NotFound />
              </MainContainer>
            }
          />
        </Routes>
      </Suspense>
    </TeachContext.Provider>
  );
  //!SECTION
};
//!SECTION

function App() {
  return (
    <BrowserRouter>
      <CoreProvider
        firebaseApp={app}
        logo={process.env.REACT_APP_LOGO}
        profileMenu={<ProfileAddonsMenu />}
      >
        <Routing />
      </CoreProvider>
    </BrowserRouter>
  );
}

export default App;

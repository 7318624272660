import {
  BoxProps,
  List,
  ListItem,
  ListItemText,
  Skeleton,
} from "@mui/material";
import { BackLink } from "components/core-sub/BackLink";
import { Breadcrumb } from "components/core-sub/ContentHeader";
import { useCore } from "components/core-sub/context";
import { Course, Section } from "components/core-sub/Controller";
import { KuiListItemButton } from "components/core-sub/KuiListItemButton";
import MainContainer from "components/core-sub/MainContainer";
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";

export type LinkKey = "student" | "assignment" | "grading" | "inbox" | "class";

type ContextType = {
  state: {
    loading: boolean;
    course: Course;
    section: Section;
  };
  setState: Dispatch<SetStateAction<ContextType["state"]>>;
  breadcrumbs: Breadcrumb[];
};

const SectionContext = createContext<ContextType>({
  state: { loading: true, course: new Course(), section: new Section() },
  setState: () => {},
  breadcrumbs: [],
});

export const useSection = () => useContext(SectionContext)

export const TeacherContainer = (props: BoxProps) => {
  const { user, t } = useCore();
  const [state, setState] = useState<ContextType["state"]>({
    loading: true,
    course: new Course(),
    section: new Section(),
  });
  const nav = useNavigate();
  const { sectionId, "*": tab } = useParams();
  const breadcrumbs: Breadcrumb[] = [{ label: t("Home"), to: "/" }];

  useEffect(() => {
    if (user.loading === false && user.data && sectionId) {
      return Section.watch(sectionId, async (section) => {
        const course = await Course.getOne(section.parent);
        setState((s) => ({ ...s, section, loading: false, course }));
      });
    }
  }, [user, sectionId]);

  return (
    <SectionContext.Provider value={{ state, setState, breadcrumbs }}>
      <MainContainer
        signInOnly
        sidebar={
          <>
            <BackLink divider to="/" />
            <ListItem divider>
              <ListItemText
                primary={t("Title")}
                secondary={state.section?.title || <Skeleton width={"50%"} />}
                primaryTypographyProps={{
                  variant: "caption",
                  color: "textSecondary",
                }}
                secondaryTypographyProps={{
                  variant: "h6",
                  color: "textPrimary",
                }}
              />
            </ListItem>
            <List dense>
              {(
                [
                  { label: t("Student"), key: "student" },
                  { label: t("Assignment"), key: "assignment" },
                  { label: t("Grading"), key: "grading" },
                  { label: t("Class"), key: "class" },
                  // { label: t("Inbox"), key: "inbox" },
                ] as {
                  label: React.ReactNode;
                  key: LinkKey;
                }[]
              ).map((item) => (
                <KuiListItemButton
                  divider
                  key={item.key}
                  selected={(tab || "student") === item.key}
                  onClick={() =>
                    nav(`/teacher/section/${sectionId}/${item.key}`)
                  }
                >
                  <ListItemText
                    primary={item.label}
                    primaryTypographyProps={{ variant: "body2" }}
                  />
                </KuiListItemButton>
              ))}
            </List>
          </>
        }
      >
        {props.children}
      </MainContainer>
    </SectionContext.Provider>
  );
};

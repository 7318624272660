import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
} from "@mui/material";
import { useCore } from "components/core-sub/context";
import { PickIcon } from "components/core-sub/PickIcon";
import * as React from "react";
import { useNavigate } from "react-router-dom";

const ListItemButtonStyled = styled(ListItemButton)({});
ListItemButtonStyled.defaultProps = {
  divider: true,
};

export const ProfileAddonsMenu = () => {
  const nav = useNavigate();
  const { t } = useCore();

  return (
    <React.Fragment>
      <ListItemButtonStyled onClick={() => nav("/")}>
        <ListItemIcon>
          <PickIcon icon={"home"} />
        </ListItemIcon>
        <ListItemText primary={t(`Home`)} />
      </ListItemButtonStyled>
      <ListItemButtonStyled onClick={() => nav("/manage/student")}>
        <ListItemIcon>
          <PickIcon icon={"graduation-cap"} />
        </ListItemIcon>
        <ListItemText primary={t(`Manage $Name`, {name:t("Student")})} />
      </ListItemButtonStyled>
      <ListItemButtonStyled onClick={() => nav("/manage/teacher")}>
        <ListItemIcon>
          <PickIcon icon={"chalkboard-teacher"} />
        </ListItemIcon>
        <ListItemText primary={t(`Manage $Name`, { name: t("Teacher") })} />
      </ListItemButtonStyled>
    </React.Fragment>
  );
};

import { db } from "components/core-sub/Controller/firebase";
import { MainStatic } from "components/core-sub/Controller/main.static";
import { User } from "firebase/auth";
import {
  deleteField,
  doc,
  onSnapshot,
  Unsubscribe,
  updateDoc,
} from "firebase/firestore";

export type Role = "anonymous" | "user" | "teacher" | "owner";

export type RoleUser = {
  displayName: string;
  email: string;
  photoURL: string;
  uid: string;
};

export type RoleUserWithRole = RoleUser & {
  id: string;
  role: string;
};

export class TeachCtl extends MainStatic {
  static async getRole(user: User): Promise<{ role: Role }> {
    const result = await this.get<{ role: Role }>(
      user,
      `${this.baseUrl()}/teach/role/${this.prefix}`,
      "GET"
    );
    return result;
  }
  static async getUserFromEmail(user: User, email: string) {
    const result = await this.get(
      user,
      `${this.baseUrl()}/user/email`,
      "POST",
      JSON.stringify({
        email,
      })
    );
    return result as RoleUser;
  }

  static async getUsers(user: User, uids: string[]) {
    const result = await this.get<RoleUser[]>(
      user,
      `${this.baseUrl()}/user/list`,
      "POST",
      JSON.stringify({ uids })
    );
    return result;
  }

  static async addTeacher(userId: string) {
    if (this.prefix) {
      return await updateDoc(doc(db, "roles", this.prefix), {
        [userId]: "teacher",
      });
    }
  }
  static async remove(teacherId: string) {
    if (this.prefix) {
      return await updateDoc(doc(db, "roles", this.prefix), {
        [teacherId]: deleteField(),
      });
    }
  }
  static async changeRole(teacherId: string, roleText: string) {
    if (this.prefix) {
      return await updateDoc(doc(db, "roles", this.prefix), {
        [teacherId]: roleText,
      });
    }
  }

  static watch = {
    teacher: (
      user: User,
      callback: (users: RoleUserWithRole[]) => void
    ): Unsubscribe => {
      if (this.prefix) {
        return onSnapshot(doc(db, "roles", this.prefix), (snapshot) => {
          const users = Object.keys(snapshot.data() || {}).map((id) => ({
            id,
            role: (snapshot.data() || {})[id],
          }));
          const uids = users.map((user) => user.id);
          this.getUsers(user, uids).then((data) => {
            const newUsers = users
              .map((user) => {
                const info = data.find((u) => u.uid === user.id);
                return info ? { ...user, ...info } : null;
              })
              .filter((u) => u) as RoleUserWithRole[];
            callback(newUsers);
          });
        });
      } else {
        return () => {};
      }
    },
  };
}
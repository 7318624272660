import { Role } from "controllers/teach.ctl";
import { createContext, useContext } from "react";

export const TeachContext = createContext<{
  role: {
    loading: boolean;
    value: Role;
  };
}>({
  role: {
    loading: true,
    value: "anonymous",
  },
});
export const useTeach = () => useContext(TeachContext);
